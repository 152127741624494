<template>
  <div class="dialog-content">
    <div class="content">
      <div class="x-f">
        <div class="marB10 fontBold" style="margin-right:50px">{{`工艺名称：${dialog.typeInfo.craftLineName}`}}</div>
        <div class="marB10 fontBold">{{`工艺编号：${dialog.typeInfo.craftLineNo}`}}</div>
      </div>
      <TablePage ref="tablePageDia" v-model="options" @handleEvent="handleEvent" />
    </div>
    <c-dialog title="新增工序" :width="500" :showDialog.sync="dialogFormVisibleTwo">
      <template #content>
        <el-form :model="formGoodsDialog" :rules="rules" ref="ruleFormGoodsDialog" label-width="120px">
          <el-form-item label="商品" prop="goodsId">
            <SelectRemote v-model="formGoodsDialog.goodsId" :option="
                  $select({
                    key: 'listGoods'
                  }).option
                " />
          </el-form-item>
          <el-form-item label="排序" prop="sortNo">
            <el-input-number v-model.trim="formGoodsDialog.sortNo" controls-position="right" :min="1"
              :max="100000"></el-input-number>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model.trim="formGoodsDialog.remark" autocomplete="off" type="textarea"
              placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>
<script>
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import {
  listCraftLineProcess,
  addCraftLineProcess,
  delCraftLineProcess,
  exchangeCraftLineProcessPosition
} from "@/api/mes/base/craftLine";
import { mixin } from "@/components/Dialog/components/mixin.js";
export default {
  name: "ProcessSteps",
  components: { TablePage: () => import("@/components/tablePage/index.vue"), CDialog, SelectRemote },
  mixins: [mixin],
  data() {
    return {
      dialogFormVisibleTwo: false,//新增、单独修改对话框
      formGoodsDialog: {
        sortNo: 1
      }, //对话框表单
      //新增表单校验
      rules: {
        goodsId: [
          {
            required: true,
            message: "关联商品不能为空",
            trigger: ["blur", "change"],
          }
        ],
        processHour: [
          {
            required: true,
            message: "工序耗时不能为空",
            trigger: ["blur", "change"],
          }
        ],
        processHourCost: [
          {
            required: true,
            message: "工序成本不能为空",
            trigger: ["blur", "change"],
          }
        ]
      },
      options: {
        mutiSelect: true,
        loading: false,
        getDataKey: { list: "data" },
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        defaultBody: () => {
          return {
            craftLineIds: this.dialog.craftLineIds,
          }
        },
        getListApi: listCraftLineProcess,
        listNo: true, // 序号
        height: '450px',
        check: [], // 选中数据
        rowKey: "billId",
        tableTitle: '',
        hideCard: true,
        noShowColumnSet: true,
        // search: [
        //   {
        //     label: "单据日期",
        //     type: "datetimerange",
        //     model: "",
        //     filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
        //   },
        //   {
        //     keyIndex: 0,
        //     format: 'datetimerange',
        //     type: 'timeRange',
        //     defaultValue: 'day',
        //     data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
        //   },
        //   { type: "button", tip: "查询", btnType: "primary", click: "search" },
        //   {
        //     type: 'button',
        //     tip: '高级查询',
        //     btnType: 'primary',
        //     click: 'seniorSearch'
        //   },
        //   { type: "button", tip: "重置", click: "reset" },
        // ],
        buttons: [
          {
            click: 'addDialog',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary',
            noRule: true
          },
          {
            click: 'handleDeleteDialog',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            alertText: '确认要删除选中的工序吗？',
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'processNo',
            label: '工序编号',
            minWidth: 120,
            align: 'center',
            formatter: (v, row) => row?.processInfo?.processNo,
          },
          {
            prop: 'processName',
            label: '工序名称',
            minWidth: 150,
            align: 'center',
            formatter: (v, row) => row?.processInfo?.processName,
          },
          {
            prop: "processHour",
            label: "工序耗时(小时)",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "processHourCost",
            label: "工序成本(小时)",
            minWidth: 110,
            align: "center",
          },
          {
            prop: 'sortNo',
            label: '排序',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: "sort",
            label: "操作",
            type: "button",
            button: [
              { text: "上移", click: "up" },
              { text: "下移", click: "down" },
            ],
            minWidth: 100,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async submit() {
      try {
        await this.$refs["ruleFormGoodsDialog"].validate();
        this.formGoodsDialog = {
          ...this.formGoodsDialog,
          craftLineId: this.dialog.typeInfo.craftLineId,
          produceDeptId: this.dialog.typeInfo.produceDeptId,
          workshopId: this.dialog.typeInfo.workshopId
        };
        await addCraftLineProcess(this.formGoodsDialog);
        this.$message({
          message: "新增工序成功~",
          type: "success",
        });
        this.cancel();
        this.$refs.tablePageDia.getList();
      } catch (error) {
        console.log('error', error);
      }
    },
    //新增取消
    cancel() {
      this.dialogFormVisibleTwo = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.formGoodsDialog = {
        sortNo: 1
      };
      this.resetForm("ruleFormGoodsDialog");
    },
    async handleEvent(type, row, rowIndex) {
      let arr = []
      switch (type) {
        case 'addDialog':
          {
            this.reset()
            this.dialogFormVisibleTwo = true
          }
          break
        case 'handleDeleteDialog':
          {
            arr = this.options.check.map(item => ({ lineId: item.lineId }))
            await delCraftLineProcess(arr)
            this.$message.success("删除成功~");
            this.$refs.tablePageDia.getList();
          }
          break
        case 'up':
          {
            if (rowIndex == 0) return this.$message.warning("已经是第一行啦~");
            await exchangeCraftLineProcessPosition({
              exchangeLineIdA: this.options.list[rowIndex - 1].lineId,
              exchangeLineIdB: row.lineId
            }
            )
            this.$message.success("上移成功~");
            this.$refs.tablePageDia.getList();
          }
          break
        case 'down':
          {
            if (this.options.list.length == rowIndex + 1) return this.$message.warning("已经是最后一行啦~");
            await exchangeCraftLineProcessPosition({
              exchangeLineIdA: this.options.list[rowIndex + 1].lineId,
              exchangeLineIdB: row.lineId
            }
            )
            this.$message.success("下移成功~");
            this.$refs.tablePageDia.getList();
          }
          break
        default:
          break
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  ::v-deep #table-page {
    height: auto;
    padding: 0;
  }
  ::v-deep .el-card {
    margin-bottom: 0px !important;
    border: none !important;
    box-shadow: none !important;
    .cardContent {
      padding: 0 !important;
    }
  }
}
</style>

